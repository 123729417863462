import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export default function PremiumBadge({
  size,
  className
}: {
  size?: SizeProp;
  className?: string;
}) {
  return (
    <FontAwesomeIcon
      icon={faCheckCircle}
      className={classNames("text-acc", className)}
      size={size || "sm"}
      fixedWidth
    />
  );
}

export function PremiumOnlyBadge({ className }: { className?: string }) {
  return (
    <span
      className={classNames(
        "flex px-2 rounded-full text-xxs font-medium bg-acc/10 text-acc",
        className
      )}
    >
      Premium
    </span>
  );
}

