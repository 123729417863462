import { useState, useEffect, memo } from "react";
import PremiumBadge from "../Premium";
import { cache } from "~/utils/cache";
import classNames from "classnames";
import { useNavigate } from "@remix-run/react";
import { getPostingJsonMetadata } from "~/utils/parse";

interface DisplayNameProps {
  name: string;
  className?: string;
  premium?: boolean | undefined;
  authorName?: string;
}

const DisplayName = memo(
  ({ name, className, authorName, premium: isPremium }: DisplayNameProps) => {
    const [premium, setPremium] = useState<boolean>(false);
    const navigate = useNavigate();
    const [displayname, setDisplayName] = useState<string>(name || authorName);

    useEffect(() => {
      if (!authorName) return;
      // TODO : delete it after sure
      // cache.getPremium(authorName).then(premium => {
      //   setPremium(premium.is_premium);
      // });

      cache.getAccount(authorName).then(account => {
        setPremium(!!account?.premium.is_premium);
        if (account.posting_json_metadata) {
          const accountData = getPostingJsonMetadata(account);
          accountData.profile.name && setDisplayName(accountData.profile.name);
        }
      });
    }, [authorName]);

    return (
      <span
        className={classNames(
          "flex items-center gap-x-1.5 font-bold text-pri dark:text-pri-d hover:underline hover:cursor-pointer",
          className
        )}
        itemProp="url"
        content={`https://inleo.io/profile/${authorName}`}
        onClick={e => {
          if (authorName) {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/profile/${authorName}`);
          }
        }}
      >
        <span className="leading-none" itemProp="name">
          {displayname}
        </span>
        <span className="flex w-[17.5px] h-[14px]">
          {(premium || isPremium) && <PremiumBadge />}
        </span>
      </span>
    );
  }
);

DisplayName.displayName = "DisplayName";
export default DisplayName;
